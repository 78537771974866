<div class="text-center" [style.backgroundColor]="data.primaryColor">
    <h2>Por favor, iniciar sesión para obtener este beneficio</h2>
    <div class="row">
        <div class="col-xs-12 col-md-6">
            <button class="btn-no" mat-raised-button [mat-dialog-close]="">Más tarde</button>
        </div>
        <div class="col-xs-12 col-md-6">
            <button class="btn-yes" mat-raised-button [mat-dialog-close]="" 
                [style.backgroundColor]="data.secondaryColor" (click)="goTo('landing-login/')">Ahora</button>
        </div>
    </div>
</div>