import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PublicService } from 'src/app/public/public.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  newsletterForm: FormGroup;
  showAlax = false;
  constructor(
    private fb: FormBuilder,
    private publicService: PublicService,
    private snackBar: MatSnackBar
  ) { 
    if (environment.marketplace) {
      this.showAlax = true;
    }
  }

  ngOnInit() {
    this.newsletterForm = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      origen: new FormControl('', []),
    });
  }

 subscribeNewsletter() {
    const data = {
      email: this.newsletterForm.value.email,
      origen: this.newsletterForm.value.origen = 'cuponeala_web',
    };
    this.publicService.subscribeNewsletter(data).subscribe((result) => {
      if (result) {
        this.openSnackBar('Se ha suscripto al newsletter', '');
      } else {
        this.openSnackBar('Se produjo un error', '');
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
