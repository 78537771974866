import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  environment = environment;
  clientsLogin = false;
  showCupo = false;
  showAlax = true;
  routeUrl: string;
  pagesWithNoFooter = ['account-delete', 'descargar-app', 'landing-login', 'landing-registro',
    'landing-verificacion-registro', 'landing-recuperar-clave', 'landing-verificacion-recuperar-clave', 'landing-definir-clave', 
    'landing-mi-perfil', 'landing-mi-perfil-avanzado', 'landing-mis-descuentos', 'landing-recurrency'];
  @Output() categorieFilter =  new EventEmitter<any>()
  constructor(
    private router: Router,
  ) {
    console.log(environment.marketplace);
    
    if (environment.marketplace) {
      this.showCupo = true;
      this.showAlax = false;
    } else {
      if (this.pagesWithNoFooter.some(p => this.router.url.includes(p))) {
        this.showAlax = false;
      }
    }
   }

  goToCategorie(categorie) {
    this.router.navigate(['/cupones-descuentos/', categorie]);
    this.categorieFilter.emit(categorie)
  }

  logged() {
    const response = localStorage.getItem('token');    
    if (response != null) {
      return true;
    }
  }

}
