<agm-map [style.width]="width" [style.height]="height" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
    <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.latitud" [longitude]="m.longitud"
        [label]="m.label" [iconUrl]="secondaryColor ? 'assets/images/ubicacion-icono-generico.png' : 'assets/images/ubicacion-icono.png'">
        <agm-info-window>
            <div>
                    <div style="background:#FFFFFF !important; border-radius:4px !important;"
                        class="deal-item bg-light cupon-templateis-info-window">
                        <div class="media d-flex" style="min-height:183px;">
                            <div class="img-content align-self-center"
                                style="width:120px;display:block; text-align:center!important;"><img *ngIf="m.cupon_imagen"
                                    src="{{m.cupon_imagen}}" alt="{{m.local_nombre}}" class="img-fluid"
                                    style="height:100px;border:2px solid #C9C9C9;margin-bottom:5px;"><span
                                    id="fecha-editable" style="font-size: 12px;margin:0;padding:0;margin-left:10px;line-height:10px;position:static;"
                                    [style.color]="primaryColor ? primaryColor : '#4DBAE3'">{{m.fecha_desde| date: 'dd/MM'}} 
                                    {{'coupon.to_the' | translate}} {{m.fecha_hasta| date: 'dd/MM'}} </span><span
                                    style="color:rgba(96, 96, 96, 0.6);font-size:9px;margin:0;padding:0;margin-left:10px;position:static;"
                                    class="texto-aclaracion">{{'coupon.stock' | translate}}</span></div>
                            <div class="media-body" style="padding: 5px; padding-bottom: 0px;margin-left:3em;">
                                <div class="cupon-contenido-texto" style="margin-top:20px !important;">
                                    <h5 class="mt-0" id="descuento-editable" style="text-align:left;text-transform: uppercase;"
                                        [style.color]="primaryColor ? primaryColor : '#009CD7'">{{m.nombre}}</h5>
                                    <h4 id="titulo-editable"
                                        style="text-align:left; word-break:break-word;text-transform: uppercase;">
                                        {{m.local_nombre}}</h4>
                                    <h6 style="text-align:left;"> <i class="fa fa-map-marker"
                                            style="padding-right:3px; padding-left:0;"></i>{{m.barrio}} </h6>
                                    <p id="descripcion-editable" style=" text-align:left;margin-top:2px;">{{m.descripcion}}</p>
                                    <div class="new_design_aux2" style="display:none">
                                        <p id="legales-cupon"
                                            style="margin-bottom:30px;height:100%;word-break:break-word;"></p>
                                        <div id="legales-cupon-aux" style="display:none"></div>
                                    </div>
                                </div>
                                <div class="dias">
                                    <div class="days"
                                        style="position:absolute; top:0; right:10px;margin:7px 16px 5px 0px;">
                                        <span id="domingo-editable" [ngClass]="{selected:m.dia_do}" [style.backgroundColor]="m.dia_do ? primaryColor : ''">D</span>
                                        <span id="sabado-editable" [ngClass]="{selected:m.dia_sa}" [style.backgroundColor]="m.dia_sa ? primaryColor : ''">S</span>
                                        <span id="viernes-editable" [ngClass]="{selected:m.dia_vi}" [style.backgroundColor]="m.dia_vi ? primaryColor : ''">V</span>
                                        <span id="jueves-editable" [ngClass]="{selected:m.dia_ju}" [style.backgroundColor]="m.dia_ju ? primaryColor : ''">J</span>
                                        <span id="miercoles-editable" [ngClass]="{selected:m.dia_mi}" [style.backgroundColor]="m.dia_mi ? primaryColor : ''">M</span>
                                        <span id="martes-editable" [ngClass]="{selected:m.dia_ma}" [style.backgroundColor]="m.dia_ma ? primaryColor : ''">M</span>
                                        <span id="lunes-editable" [ngClass]="{selected:m.dia_lu}" [style.backgroundColor]="m.dia_lu ? primaryColor : ''">L</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-left: 10px;margin-right: 10px; margin-top:0px;margin-bottom:10px"
                            class="new_design_aux"><span (click)="sendWhatsapp(m.whatsapp)" *ngIf="m.whatsapp != null" 
                            style="font-size:14px;margin:0;padding-left:10px;margin-top:15px" [style.color]="primaryColor ? primaryColor : '#765BA7'"><i
                                    class="fab fa-whatsapp"
                                    style="font-size:20px;margin-right:2px;margin-bottom:-3px"></i>{{'coupon.share' | translate}}</span>
                                    <button (click)="openDetailCoupon(m)"
                                type="submit" class="submit" style="color:white;height:30px;border-radius:6px;border: none; margin-left:3.5rem"
                                [style.backgroundColor]="secondaryColor ? secondaryColor : '#FBAD18'">Activar cupón</button>
                        </div>
                    </div></div>
        </agm-info-window>
    </agm-marker>