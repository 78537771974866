import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import { MatMenuModule } from '@angular/material/menu';
import { NavComponent } from '../shared/components/nav/nav.component';
import { HeaderComponent } from '../shared/components/header/header.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { FooterComponent } from './components/footer/footer.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { HowWorksComponent } from './components/how-works/how-works.component';
import { RouterModule } from '@angular/router';
import { MapComponent } from './components/map/map.component';
import { AgmCoreModule } from '@agm/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CouponShareDialog } from './components/coupon/coupon-share/coupon-share.component';
import { FixedPipe } from './fixed.pipe';
import { DistrictFilter } from '../profile/my-account-advanced/district.filter';
import { MatSidenavModule } from '@angular/material/sidenav';
import {TranslateModule} from '@ngx-translate/core';
import { GeocodeService } from './services/geocoder.service';
import { Session } from './services/session.service';
import { LoadingComponent } from './components/loading/loading.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MessageDialogComponent } from './components/coupon/coupon-share/message-dialog/message-dialog.component';
@NgModule({
  declarations: [
    NavComponent,
    HeaderComponent,
    CouponComponent,
    FooterComponent,
    NewsletterComponent,
    HowWorksComponent,
    MapComponent,
    CouponShareDialog,
    FixedPipe,
    DistrictFilter,
    LoadingComponent,
    MessageDialogComponent,
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    ImageCropperModule,
    ReactiveFormsModule,
    MatSidenavModule,
    FormsModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatCheckboxModule,
    RouterModule,
    MatDialogModule,
    MatMenuModule,
    TranslateModule,
    AgmCoreModule.forRoot({
      // please get your own API key here:
      // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
      apiKey: 'AIzaSyAXBrRkR4f-wtcWiWs6Y1jUoRRXWc0P3Yo'
    })
  ],
  exports: [
    NavComponent,
    HeaderComponent,
    CouponComponent,
    ImageCropperModule,
    FooterComponent,
    LoadingComponent,
    NewsletterComponent,
    MatSidenavModule,
    HowWorksComponent,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatMenuModule,
    FormsModule,
    MatProgressBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MapComponent,
    MatSnackBarModule,
    CouponShareDialog,
    FixedPipe,
    DistrictFilter,
    TranslateModule
  ], providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    GeocodeService,
    Session
  ]
})
export class SharedModule { }
