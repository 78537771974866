import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PublicService } from 'src/app/public/public.service';
//import amplitude from 'amplitude-js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { TranslateCompiler, TranslateService } from '@ngx-translate/core';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
@Component({
  selector: 'app-coupon-share',
  templateUrl: './coupon-share.component.html',
  styleUrls: ['./coupon-share.component.scss'],
})
export class CouponShareDialog implements OnInit {
  unloggedCouponForm: FormGroup
  loggedCouponForm: FormGroup
  form = true
  environment : any;
  more : Boolean =  false;
  descuento_codigo_eshop;
  isPartner;
  barcode;
  transparentBorder;

  encuesta = {};
  encuestaForm: FormGroup =  new FormGroup({});
  encuestaFormIsValid = false;
  encuestaSent = false;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private publicService: PublicService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    protected $gaService: GoogleAnalyticsService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.environment = environment;    

    if (this.data.coupons.encuesta_id != null) {
      this.getEncuestaData();
    }

    this.$gaService.pageView(`${window.location.hostname}/cupon-descarga/${this.data.coupons.id}-${this.data.coupons.local_id}`, `${this.data.coupons.local_nombre} - ${this.data.coupons.nombre}`)
    this.$gaService.event('Ver Cupon','Cupones',`${this.data.coupons.id}-${this.data.coupons.local_id}`,this.data.coupons.id);
    this.unloggedCouponForm = this.fb.group({
      nombre: new FormControl(localStorage.getItem('guestName') ? localStorage.getItem('guestName') : '', Validators.required),
      apellido: new FormControl(localStorage.getItem('guestSurname') ? localStorage.getItem('guestSurname') : '', Validators.required),
      dni: new FormControl(localStorage.getItem('guestDni') ? localStorage.getItem('guestDni') : '', Validators.required),
      email: new FormControl(localStorage.getItem('guestEmail') ? localStorage.getItem('guestEmail') : '', [Validators.required, Validators.email]),
      movil_codigo_area: new FormControl(localStorage.getItem('guestMovilCodigoArea') ? localStorage.getItem('guestMovilCodigoArea') : null),
      movil_numero: new FormControl(localStorage.getItem('guestMovilNumero') ? localStorage.getItem('guestMovilNumero') : null),
      mayoria_edad: new FormControl(),
      acepto_terminos: new FormControl(0, Validators.required)
    });
    
    this.loggedCouponForm = this.fb.group({
      id: new FormControl(this.data.coupons.id, Validators.required),
      local_id: new FormControl(this.data.coupons.local_id, Validators.required),
      origen: new FormControl('web', Validators.required)
    });

    if (this.data.primaryColor) {
      this.transparentBorder = `transparent transparent ${this.data.primaryColor} transparent`;
    }
  }
  
  logged() {
    if (this.data.landing.registro_usuarios) {
      return true;
    } else {
      return (localStorage.getItem('token') && !localStorage.getItem('isClient'));
    }
  }

  share(provider: string){

    this.$gaService.event('Compartir Cupon','Cupones',provider,this.data.coupons.id);
    let couponUrl = `${environment.shareUrl}/${environment.countryId}/cupon/${this.data.coupons.local_id}/${this.data.coupons.id}/${this.data.coupons.seotag}/${this.data.coupons.local_nombre.replaceAll(' ', '-').toLowerCase()}`;
    
    let message = `${couponUrl}\n${this.data.coupons.nombre}\n${this.data.coupons.local_nombre}\n${this.data.coupons.descripcion}`;
   
    var url = '';
    if (provider == 'W') {
      url = `https://api.whatsapp.com/send?text=${encodeURI(message)}`;
    } else if (provider == 'T') {
      url = `https://twitter.com/intent/tweet?text=${encodeURI(message)}`;
    } else if (provider == 'F') {
      url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
        couponUrl
      )}&quote=${encodeURI(message)}`;
    }

   //   amplitude.getInstance().logEvent('Sharing Coupon',{coupon: this.data.coupons.id,  shop:this.data.coupons.local_id, name: this.data.coupons.local_nombre, type: this.data.coupons.nombre, category:this.data.coupons.categoria_nombre,channel: provider});
      window.open(url, "_blank");
  }
  
  sendWhatsapp(phone){
    this.$gaService.event('Contacto Whatsapp','Cupones',`${this.data.coupons.id}-${this.data.coupons.local_id}`,this.data.coupons.id);
    var message =  this.translate.instant('coupon.share_whatsapp');
    message = encodeURI(message);
    window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${message}`, '_blank');
  }
  
  vermas(){
    this.more = !this.more;
  }

  web(){
    if (this.data.coupons.url_eshop) {
      return `${this.data.coupons.url_eshop}`
    } else {
      return `${this.data.coupons.url_protocolo}${this.data.coupons.local_nombre}`
    }
  }

  loggedActivateCoupon() {

    var data = {
      origen: this.loggedCouponForm.value.origen = 'web',
      local_id: this.loggedCouponForm.value.local_id,
      id: this.loggedCouponForm.value.id,
      landing_id : null,
      token: null
    }

    var amplitudeParams = {coupon: data.id,  shop: data.local_id, name: this.data.coupons.local_nombre, type: this.data.coupons.nombre, landing_id : null};
    if (this.data.landing){
      data.origen = "landing";
      data.landing_id = this.data.landing.id;
      amplitudeParams.landing_id =this.data.landing.id;

      if (this.data.landing.registro_usuarios) {
        if (!localStorage.getItem('token_'+this.data.landing.cliente_id)) {
          const dialogRef = this.dialog.open(MessageDialogComponent, {
            panelClass: ['dialog', 'message'],
            data: this.data,
          });
          dialogRef.afterClosed().subscribe((result) => {});
          return false;
        } else {
          data.token = localStorage.getItem('token_'+this.data.landing.cliente_id);
        }
      }
    }
    
    this.publicService.activateCouponLogged(data).subscribe(
      result => {
        this.isPartner = result.partner_code;

        this.$gaService.event('Descarga Cupon','Cupones',`${this.data.coupons.id}-${this.data.coupons.local_id}`,this.data.coupons.id);
     //   var revenue = new amplitude.Revenue().setProductId(`Coupon ${data.id}`).setPrice(1).setQuantity(1);
       //   amplitude.getInstance().logRevenueV2(revenue);
         // amplitude.getInstance().logEvent('Activating Coupon',amplitudeParams);
         
          this.openSnackBar('Su cupon ha sido activado', '');
        this.form = false
      },
      (errors) => {
        this.openSnackBar(errors.error.errors[0].msg, '');
      })
  }

  unloggedActivateCoupon(shop, id, origen) {

    var movil = null;
    if(this.data.coupons.pedir_whatsapp != 0) {
      if (this.unloggedCouponForm.value.movil_codigo_area && this.unloggedCouponForm.value.movil_codigo_area) {
        movil = environment.codigo_pais+'-'+this.unloggedCouponForm.value.movil_codigo_area+'-'+this.unloggedCouponForm.value.movil_numero;
      }
    }

    var mayor = null;
    if (this.unloggedCouponForm.value.mayoria_edad) {
      mayor = 1;
    }

    var data = {
      nombre: this.unloggedCouponForm.value.nombre,
      apellido: this.unloggedCouponForm.value.apellido,
      dni: this.unloggedCouponForm.value.dni.toString(),
      email: this.unloggedCouponForm.value.email,
      movil: movil,
      mayor_18: mayor,
      acepto_terminos: 1,
      origen: origen,
      local_id: shop,
      id: id,
      landing_id : null,
      encuesta_id: null,
      encuesta_data: null
    };
    console.log("local id: "+data.local_id);
    
    if (this.data.coupons.encuesta_id) {
      data.encuesta_id = this.data.coupons.encuesta_id;
      data.encuesta_data = this.getEncuestaValores(this.encuesta);      
    }

   // var amplitudeParams = {coupon: data.id,  shop: data.local_id, name: this.data.coupons.local_nombre, type: this.data.coupons.nombre, landing_id : null};
    if (this.data.landing){
      data.origen = "landing";
      data.landing_id = this.data.landing.id;
 //     amplitudeParams.landing_id = this.data.landing.id;
    }    

    this.publicService.activateCoupon(data).subscribe(
      result => {
          this.isPartner = result.partner_code;
          this.form = false
          this.descuento_codigo_eshop = result.codigo_eshop;
          this.barcode = result.barcode;
          console.log(this.data);
          this.$gaService.event('Descarga Cupon','Cupones',`${this.data.coupons.id}-${this.data.coupons.local_id}`,this.data.coupons.id);
          this.encuestaSent = true;
        //  var revenue = new amplitude.Revenue().setProductId(`Coupon ${data.id}`).setPrice(1).setQuantity(1);
        //  amplitude.getInstance().logRevenueV2(revenue);
        //  amplitude.getInstance().logEvent('Activating Coupon',amplitudeParams);
          this.openSnackBar('Su cupon ha sido activado', '');
          localStorage.setItem('guestName', this.unloggedCouponForm.value.nombre)
          localStorage.setItem('guestSurname', this.unloggedCouponForm.value.apellido)
          localStorage.setItem('guestDni',  this.unloggedCouponForm.value.dni)
          localStorage.setItem('guestEmail', this.unloggedCouponForm.value.email)
          if (this.unloggedCouponForm.value.movil_codigo_area && this.unloggedCouponForm.value.movil_codigo_area) {
            localStorage.setItem('guestMovilCodigoArea',  this.unloggedCouponForm.value.movil_codigo_area)
            localStorage.setItem('guestMovilNumero', this.unloggedCouponForm.value.movil_numero)
          }
      },
      (errors) => {
        this.openSnackBar(errors.error.errors[0].msg, '');
      }
    );
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getEncuestaData() {
    var data = {
      id: this.data.coupons.encuesta_id
    }

    this.publicService.getEncuestaData(data).subscribe(
      (results) => {
        this.encuesta = results;
        this.encuestaForm = this.generateEncuestaForm(results);
      }, (error) => {
        console.log(error);
      }
    )
  }

  generateEncuestaForm(encuesta) {
    let tempGroup: FormGroup = new FormGroup({});
    var fieldNumber = 0;
    encuesta.preguntas.forEach(i=>{
        if (i.obligatoria) {
          tempGroup.addControl('encuesta-pregunta-id-'+i.id, new FormControl('', Validators.required));
        } else {
          tempGroup.addControl('encuesta-pregunta-id-'+i.id, new FormControl(''));
        }
        fieldNumber += 1;
    })    
    return tempGroup;
  }

  onEncuestaChange() {
    this.encuestaForm.updateValueAndValidity();
    this.encuestaFormIsValid = this.encuestaForm.valid;
  }

  getEncuestaValores(encuesta) {
    var surveyData = {
      preguntas_ids: [],
      preguntas_resps: []
    };
    encuesta.preguntas.forEach(i=>{
      surveyData.preguntas_ids.push(i.id);
      surveyData.preguntas_resps.push(this.encuestaForm.controls['encuesta-pregunta-id-'+i.id].value);
    });
    return surveyData;
  }

}
